<template>
  <div class="staff-list">
    <el-tabs class="tabs" @tab-click="handleClick">
      <el-tab-pane label="角色成员">
        <top-content @handleBatchRemove="handleBatchRemove" :roleId="roleId"></top-content>
        <page-content :contentTableConfig="contentTableConfig" :isButtonStyle="true" :dataList="roleIdStaffList"
          :dataCount="10" @tableCellClick="tableCellClick" @selectionChange="tableSelectionChange">
          <template #userRealname="scope">
            <div class="userRealname">
              <img :src="scope.row.userPhoto" alt />
              <div class="name">{{ scope.row.userRealname }}</div>
            </div>
          </template>
          <template #handler="scope">
            <div class="handle-btns">
              <el-button plain size="mini" @click.stop="handleDetailClick(scope.row)">详情</el-button>
              <el-button type="danger" plain size="mini" @click.stop="handleRemoveClick(scope.row)">移除</el-button>
            </div>
          </template>
        </page-content>
        <staff-edit-draw :isChange="isUpdateStaff" v-if="pageDialogVisible" :show="false"
          @handleResetPasswordClick="handleResetPasswordClick" :staffId="currentEditStaffId"
          @changedDrawer="handleStaffEditdDrawer" :dialogVisible="pageDialogVisible"
          @handleAssociateClick="handleAssociateClick" @handleStaffEditClick="handleStaffEditClick"></staff-edit-draw>
        <reset-password-dialog v-if="resetPasswordDialogVisible" :staffId="currentEditStaffId"
          :dialogVisible="resetPasswordDialogVisible" @changeDialogVisible="changeResetPasswordDialogVisible">
        </reset-password-dialog>
        <distribute-dialog v-if="distributeDialogVisible" :staffId="currentEditStaffId"
          :dialogVisible="distributeDialogVisible" @changeDialogVisible="changeDistributeDialogVisible">
        </distribute-dialog>

        <update-staff-form @refreshStaffList="refreshStaffList" :staffId="currentEditStaffId"
          @changeDialogVisible="changeUpdateDialogVisible" :dialogVisible="updateStaffDialogVisible">
        </update-staff-form>
      </el-tab-pane>
      <!-- <el-tab-pane label="角色权限" name="second">
        <role-purview :roleId="roleId"></role-purview>
      </el-tab-pane> -->
    </el-tabs>
    <el-button style="position: absolute;top: 40px;left: 400px;" @click="handleAuthorityClick" type="text" v-has="'staff:power'">权限配置</el-button>
    <authority-dialog :roleId="roleId" :treeData="treeData" :resourceList="resourceList" :dialogVisible="authorityDialogVisible" @changeDialogVisible="changeAuthorityDialogVisible"></authority-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch,onUnmounted } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config/index'
import PageDraw from '@/components/page-drawer/page-drawer.vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import TopContent from './cpns/top-content/top-content'
import Breadcrumb from './cpns/breadcrumb/breadcrumb'
// import RolePurview from './cpns/role-purview/role-purview.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import { UPLOAD_BASE_URL } from '@/common/constant'
import emitter from '@/utils/eventbus'
import DistributeDialog from '@/views/main/staff/base-ui/distribute-dialog/distribute-dialog.vue'
import ResetPasswordDialog from '@/views/main/staff/base-ui/reset-password-dialog/reset-password-dialog.vue'
import updateStaffForm from '@/views/main/staff/base-ui/update-staff-form/update-staff-form.vue'
import staffEditDraw from '@/views/main/staff/base-ui/staff-edit-draw/staff-edit-draw.vue'
import { useRouter } from 'vue-router'
import AuthorityDialog from './cpns/top-content/cpns/authority-dialog/authority-dialog.vue'
import { getResourceList , getUserAuthorityResourceListIds } from '@/service/main/staff'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    roleId: {
      type: Number,
      default: -1
    }
  },
  components: {
    DistributeDialog,
    ResetPasswordDialog,
    staffEditDraw,
    PageContent,
    PageDraw,
    PageDialog,
    TopContent,
    Breadcrumb,
    // RolePurview,
    updateStaffForm,
    AuthorityDialog
  },
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const authorityDialogVisible = ref(false)
    const treeData = ref([])
    const resourceList = ref([])
    const resetPasswordDialogVisible = ref(false)
    const changeAuthorityDialogVisible = (flag) => {
      // console.log(flag);
      authorityDialogVisible.value = flag
    }
    const handleResetPasswordClick = () => {
      resetPasswordDialogVisible.value = true
    }

    const pageDialogVisible = ref(false)
    const handleStaffEditdDrawer = (flag) => {
      pageDialogVisible.value = flag
    }

    const updateStaffDialogVisible = ref(false)
    const changeUpdateDialogVisible = (flag) => {
      updateStaffDialogVisible.value = flag
    }

    const handleStaffEditClick = () => {
      // updateStaffDialogVisible.value = true
      router.push({
        path:'/main/staff/editStaffPage',
        query:{
          staffId:currentEditStaffId.value
        }
      })
    }

    const distributeDialogVisible = ref(false)
    const handleAssociateClick = () => {
      distributeDialogVisible.value = true
    }

    const changeDistributeDialogVisible = (flag) => {
      distributeDialogVisible.value = flag
    }

    const changeResetPasswordDialogVisible = (flag) => {
      resetPasswordDialogVisible.value = flag
    }


    const isShowEditStaffDraw = ref(false)
    const handleDetailClick = (item) => {
      // isShowEditStaffDraw.value = true
      currentEditStaffId.value = item.userId
      pageDialogVisible.value =  true
    }
    const changedEditStaffDrawer = (flag) => {
      isShowEditStaffDraw.value = flag
    }

    const currentSelectionStaffList = ref([])
    const tableSelectionChange = (list) => {
      currentSelectionStaffList.value = list
    }

    const handleBatchRemove = () => {
      if (currentSelectionStaffList.value.length == 0) return
      store.dispatch('staff/staffBatchRemoveAction', {
        roleId: props.roleId,
        staffList: currentSelectionStaffList.value,
        companyId: COMPANY_ID
      })
    }

    const currentEditStaffId = ref('')
    const tableCellClick = (item) => {
      currentEditStaffId.value = item.userId
      pageDialogVisible.value = true
    }

    watch(() => props.roleId, (n) => {
      store.dispatch('staff/getRoleIdUserListAction', [props.roleId,
        COMPANY_ID])
    })

    const handleRemoveClick = async (item) => {
      await store.dispatch('staff/removeRoleUserBindAction', [props.roleId, item.userId, COMPANY_ID])
	  refreshStaffList ()
    }
    const handleAuthorityClick = async () => {
      if (props.roleId == -1) {
        ElMessage({
          message: '请在左侧先选择角色！',
          type: 'warning',
        })
        return
      }
      const res = await getResourceList()
      const resd = await getUserAuthorityResourceListIds({id:props.roleId})
      if (res.code == 0) {
        treeData.value = res.data
        authorityDialogVisible.value = true
      }
      if (resd.code == 0) {
        resourceList.value = resd.data;
      }
      // console.log("111111111",authorityDialogVisible.value);
    }

    const formState = useState('staff', ['roleIdStaffList',])
    const refreshStaffList = () => {
      store.dispatch('staff/getRoleIdUserListAction', [props.roleId,
        COMPANY_ID])
      isUpdateStaff.value = !isUpdateStaff.value
    }

    const isUpdateStaff = ref(false)

    emitter.on('refreshCurrentRoleStaffPageContent', () => {
      refreshStaffList ()
    })

    onUnmounted(()=>{
      emitter.off('refreshCurrentRoleStaffPageContent')
    })

    return {
      refreshStaffList,
      isUpdateStaff,
      changeUpdateDialogVisible,
      updateStaffDialogVisible,
      changeResetPasswordDialogVisible,
      handleAssociateClick,
      handleDetailClick,
      contentTableConfig,
      isShowEditStaffDraw,
      changedEditStaffDrawer,
      COMPANY_ID,
      ...formState,
      UPLOAD_BASE_URL,
      tableCellClick,
      handleRemoveClick,
      resetPasswordDialogVisible,
      currentEditStaffId,
      pageDialogVisible,
      handleStaffEditdDrawer,
      changeDistributeDialogVisible,
      handleResetPasswordClick,
      distributeDialogVisible,
      handleStaffEditClick,
      tableSelectionChange,
      handleBatchRemove,
      handleAuthorityClick,
      changeAuthorityDialogVisible,
      treeData,
      resourceList,
      authorityDialogVisible
    }

  }
})
</script>

<style scoped lang="less">
.staff-list {
  overflow: hidden;
  flex: 1;
  padding-left: 20px;
  margin-right: 20px;
  border-top: 1px solid var(--borderColor);

  &:deep(.el-tabs__active-bar) {
    height: 0 !important;
    font-weight: 600;
  }

  &:deep(.el-tabs__nav-wrap::after) {
    height: 1px !important;
  }

  .handle-btns {
    display: flex;
    flex-wrap: wrap;

    &:deep(.el-button) {
      margin-left: 0 !important;
      margin: 4px !important;
    }
  }

  .userRealname {
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
</style>




