<template>
  <div class="top-content">
    <el-button @click="handleAddStaffClick" type="primary">添加员工</el-button>
    <!-- <el-button @click="handleBatchRemove" type="primary">批量移除</el-button> -->
    <!-- <el-button @click="handleAuthorityClick" type="primary">权限配置</el-button> -->
    <!-- <add-staff-dialog :roleId="roleId" :dialogVisible="addStaffDialogVisible" @changeDialogVisible="changeAddStaffDialogVisible"></add-staff-dialog> -->
    <authority-dialog :roleId="roleId" :treeData="treeData" :resourceList="resourceList" :dialogVisible="authorityDialogVisible" @changeDialogVisible="changeAuthorityDialogVisible"></authority-dialog>
    <rankDistributeDialog
      v-if="addStaffDialogVisible"
      @changeDialogVisible="changeAddStaffDialogVisible"
      :dialogVisible="addStaffDialogVisible"
      :roleId="roleId"
    >
    </rankDistributeDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
// import AddStaffDialog from './cpns/add-staff-dialog/add-staff-dialog.vue'
import AuthorityDialog from './cpns/authority-dialog/authority-dialog.vue'
import rankDistributeDialog from './cpns/rank-distribute-dialog/rank-distribute-dialog.vue'
import { tips } from '@/utils/tips'
import { ElMessage } from 'element-plus'
import { getResourceList , getUserAuthorityResourceListIds } from '@/service/main/staff'

export default defineComponent({
  props: {
    roleId: {
      type: Number,
      default: -1
    }
  },
  components: {
    // AddStaffDialog,
    AuthorityDialog,
    rankDistributeDialog
  },
  emits: ['handleBatchRemove'],
  setup(props, { emit }) {
    const addStaffDialogVisible = ref(false)
    const authorityDialogVisible = ref(false)
    const treeData = ref([])
    const resourceList = ref([])
    const changeAddStaffDialogVisible = (flag) => {
      addStaffDialogVisible.value = flag
    }
    const changeAuthorityDialogVisible = (flag) => {
      // console.log(flag);
      authorityDialogVisible.value = flag
    }
    const handleAddStaffClick = () => {
      if (props.roleId == -1) {
        ElMessage({
          message: '请在左侧先选择角色！',
          type: 'warning',
        })
        return
      }
      addStaffDialogVisible.value = true
    }
    const handleAuthorityClick = async () => {
      if (props.roleId == -1) {
        ElMessage({
          message: '请在左侧先选择角色！',
          type: 'warning',
        })
        return
      }
      const res = await getResourceList()
      const resd = await getUserAuthorityResourceListIds({id:props.roleId})
      if (res.code == 0) {
        treeData.value = res.data
        authorityDialogVisible.value = true
      }
      if (resd.code == 0) {
        resourceList.value = resd.data;
      }
      // console.log("111111111",authorityDialogVisible.value);
    }

    const handleBatchRemove = () => {
      emit('handleBatchRemove')
    }

    return {
      addStaffDialogVisible,
      authorityDialogVisible,
      changeAddStaffDialogVisible,
      changeAuthorityDialogVisible,
      treeData,
      resourceList,
      handleAddStaffClick,
      handleAuthorityClick,
      handleBatchRemove
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  background-color: white;
  margin-bottom: 16px;
  // border-top: 1px solid var(--borderColor);
}
</style>




