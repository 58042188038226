<template>
  <div class="top-content">
    <el-button @click="handleAddRoleClick" type="primary" v-has="'staff:role:add'">
      <el-icon>
        <plus />
      </el-icon>新增角色
    </el-button>
    <el-button style="opacity: 0;">
      <el-icon>
        <plus />
      </el-icon>新增角色
    </el-button>
    <add-role-dialog
      :roleId="roleId"
      :dialogVisible="addRoleDialogVisible"
      @changeDialogVisible="changeAddRoleDialogVisible"
    ></add-role-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import AddRoleDialog from './cpns/add-rank-dialog/add-rank-dialog'
import { tips } from '@/utils/tips'
export default defineComponent({
  props: {
    roleId: {
      type: Number,
      default: -1
    }
  },
  components: {
    AddRoleDialog,
  },
  setup(props) {
    const addRoleDialogVisible = ref(false)
    const handleAddRoleClick = () => {
      addRoleDialogVisible.value = true
    }
    const changeAddRoleDialogVisible = (flag) => {
      addRoleDialogVisible.value = flag
    }

    return {
      handleAddRoleClick,
      changeAddRoleDialogVisible,
      addRoleDialogVisible,

    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  // padding: 18px;
  // margin: 0 16px;
}
</style>
