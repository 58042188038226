<template>
  <div class="role-management">
    <top-content :roleId="currentEditRoleId"></top-content>
    <div class="content">
      <rank-category @handleTreeNodeClick="handleTreeNodeClick"></rank-category>
      <staff-list :roleId="currentEditRoleId"></staff-list>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import TopContent from './cpns/top-content/top-content'
import RankCategory from './cpns/rank-category/rank-category'
import StaffList from './cpns/staff-list/staff-list'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    TopContent,
    RankCategory,
    StaffList
  },
  setup() {

    const currentEditRoleId = ref(-1)
    const handleTreeNodeClick = (roleId) => {
      currentEditRoleId.value = roleId
    }


    return {
      breadcrumbList,
      handleTreeNodeClick,
      currentEditRoleId
    }

  }
})
</script>

<style scoped lang="less">
.role-management {
  box-sizing: border-box;
  min-width: 980px;

  .content {
    display: flex;
    justify-content: space-between;
    background-color: white;
  }
}
</style>
