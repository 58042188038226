<template>
  <div class="add-rank-dialog">
    <page-dialog @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="新增角色"
      :isShowFooter="true" @handleSaveClick="handleSaveClick">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="80px" class="demo-ruleForm"
        label-position="left">
        <el-form-item label="角色归属:" prop="office">
          <el-select v-model="ruleForm.kindId" class="m-2" placeholder="选择职位" size="large">
            <el-option v-for="item in rankCategory" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色名称:" prop="name">
          <el-input v-model="ruleForm.roleName"></el-input>
        </el-form-item>

      </el-form>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { rankCategory } from './config'
import emitter from '@/utils/eventbus'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    roleId: {
      type: Number,
      default: 0
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const ruleForm = reactive({
      roleName: '',
      kindId: ''
    })
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const handleSaveClick = () => {
      store.dispatch('staff/saveStaffRoleAction', {
        companyId: COMPANY_ID,
        ...ruleForm
      })
    }

    const initPage = () => {

    }
    initPage()



    return {
      changeDialogVisible,
      ruleForm,
      handleSaveClick,
      rankCategory
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  font-size: 12px;

  &:deep(.el-select) {
    width: 100%;
  }
}
</style>




