<template>
  <div class="rank-category">
    <page-tree :treeProps="{ label: 'roleName' }" :agencyListInfo="roleCategoryList"
      @handleNodeClick="handleTreeNodeClick">
      <template #edit="row">
        <div v-if="!row.row.data.children" class="edit" @click.stop="handleRowClick(row)" v-has="'staff:role:edit'">编辑</div>
      </template>
      <template #remove="row">
        <div v-if="!row.row.data.children" class="remove" @click.stop="handleRemoveClick(row)" v-has="'staff:role:delete'">删除</div>
      </template>
    </page-tree>
    <updateRole :roleInfo="currentEditRoleInfo" v-if="updateDialogVisible" :dialogVisible='updateDialogVisible'
      @changeDialogVisible='changeupdateDialogVisible'>
    </updateRole>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted } from 'vue'
import PageTree from '@/components/page-tree/page-tree.vue'
import { useState } from '@/hooks'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
import updateRole from '../../base-ui/update-dialog/update-dialog.vue'
import { removeRole } from '@/service/main/staff'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {

  },
  components: {
    PageTree,
    updateRole
  },
  emits: ['handleTreeNodeClick'],
  setup(props, { emit }) {
    const store = useStore()
    const rankListState = useState('staff', ['roleCategoryList'])

    const initPage = () => {
      store.dispatch('staff/getRoleCategoryListAction', COMPANY_ID)
    }
    initPage()

    const currentClickRoleId = ref('')
    const handleTreeNodeClick = (item) => {
      if (item.level == 1) {
        emit('handleTreeNodeClick', -1)
        return
      }
      currentClickRoleId.value = item.data.roleId
      emit('handleTreeNodeClick', item.data.roleId)
    }

    // emitter.on('changeStaffRoleManageFirstRoId', id => {
    //   emit('handleTreeNodeClick', id)
    // })

    emitter.on('changeStaffAddROle', () => {
      initPage()
    })

    onUnmounted(() => {
      emitter.off('changeStaffRoleManageFirstRoId')
      emitter.off('changeStaffAddROle')
    })

    const currentEditRoleInfo = ref()
    const updateDialogVisible = ref(false)
    const changeupdateDialogVisible = flag => {
      updateDialogVisible.value = flag
    }

    const handleRowClick = item => {
      // console.log(item);
      let data = item.row.data
      currentEditRoleInfo.value = data
      updateDialogVisible.value = true

    }

    const handleRemoveClick = async item => {
      let data = item.row.data
      const res = await removeRole(data.roleId)
      if (res.code == 0) {
        ElMessage({
          message: '操作成功!',
          type: 'success'
        })
        initPage()
      } else {
        ElMessage({
          message: res.msg,
          type: 'warning',
        })
      }
    }

    return {
      handleRowClick,
      changeupdateDialogVisible,
      updateDialogVisible,
      currentEditRoleInfo,
      ...rankListState,
      COMPANY_ID,
      handleTreeNodeClick,
      currentClickRoleId,
      handleRemoveClick
    }

  }
})
</script>

<style scoped lang="less">
.rank-category {
  min-width: 260px;
  max-width: 320px;
  height: 100%;
  background-color: white;

  &:deep(.el-tree-node__content) {

    .remove,
    .edit {
      visibility: hidden;
      color: rgb(64, 158, 255);
      font-size: 12px;
    }

    .edit {
      margin-left: 14px;
    }

    .remove {
      margin-left: 10px;
    }
  }

  &:deep(.el-tree-node__content):hover {

    .remove,
    .edit {
      visibility: visible !important;
    }
  }

  &:deep(.is-current) {
    background-color: rgb(255, 234, 234) !important;
    color: rgba(255, 141, 26, 1);
  }
}
</style>
