<template>
  <div class="add-office-dialog">
    <page-dialog @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="修改角色名称"
      :isShowFooter="true">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px" label-position="left"
        class="demo-ruleForm">
        <el-form-item label="角色名称:" prop="office">
          <el-input v-model="ruleForm.office"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="save-btn">
          <el-button size="small" @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
        </div>
      </template>
    </page-dialog>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { rules } from '../config'
import { updateRole } from '@/service/main/staff'
import { ElMessage } from 'element-plus'
import emitter from '@/utils/eventbus'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    roleInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const ruleForm = reactive({
      office: ''
    })
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const handleSaveClick = async (formEl = undefined) => {

      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          let roleInfo = props.roleInfo
          let res = await updateRole(ruleForm.office, roleInfo.roleId)

          if (res.code == 0) {
            ElMessage({
              message: '操作成功!',
              type: 'success'
            })
            changeDialogVisible(false)
            emitter.emit('changeStaffAddROle')
          }
          // console.log('submit!')
        } else {
          // console.log('error submit!', fields)
        }
      })

    }

    const ruleFormRef = ref()
    const initPage = () => {
      let roleInfo = props.roleInfo
      ruleForm.office = roleInfo.roleName
    }
    initPage()

    return {
      ruleFormRef,
      changeDialogVisible,
      ruleForm,
      COMPANY_ID,
      handleSaveClick,
      rules
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  &:deep(.el-select) {
    width: 100%;
  }

  .save-btn {
    text-align: right;
  }
}
</style>








