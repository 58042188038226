<template>
  <div class="add-staff-dialog">
    <tree-dialog
      width="600px"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      title="权限配置"
      :isShowFooter="true"
      @handleSaveClick="handleSaveClick"
    >
      <el-tree
        :data="treeData"
        show-checkbox
        node-key="id"
        default-expand-all
        :props="defaultProps"
        :check-strictly="true"
        :default-checked-keys="resourceList"
        ref="treeRef"
      />
    </tree-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import TreeDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { useState } from '@/hooks'
import { nextTick } from 'process'
import { saveAuthority } from '@/service/main/staff'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: {  
      type: Boolean,
      default: false
    },
    roleId: {
      type: Number,
      default: -1
    },
    treeData: {
      type: Array,
      default: () => ({})
    },
    resourceList: {
      type: Array,
      default: () => ({})
    },
  },
  components: {
    TreeDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore();
    //树 ref
    const treeRef = ref()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }
    const defaultProps = {
      children: 'children',
      label: 'name',
    }

    const handleSaveClick = () => {
     let resourceIds = treeRef.value.getCheckedKeys()
      saveAuthority({resourceIds:resourceIds,roleId:props.roleId}).then(res=>{
        if (res.code == 0) {
          ElMessage({
            message: '操作成功!',
            type: 'success',
          })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }


    return {
      changeDialogVisible,
      handleSaveClick,
      defaultProps,
      treeRef
    }

  }
})
</script>

<style scoped lang="less">
.add-staff-dialog {
  font-size: 12px;

  :deep(.el-dialog) {
    margin: auto !important;
    position: static !important;
    transform: translate(0, 0) !important;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  :deep(.el-overlay-dialog) {
    position: fixed;
    top: 5%;
    right: 0;
    left: 0;
    height: 90%;
    overflow: hidden;
}

  &:deep(.el-dialog__body) {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    flex: 1;
    overflow-y: auto;
  }

  .tips {
    margin: 12px 0;
  }

  .isActive {
    border: 2px solid var(--el-color-primary);
  }

  &:deep(.el-select) {
    width: 100%;
  }
}
</style>
