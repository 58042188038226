  export  const rankCategory = [
  {
    value: '1',
    label: '总部',
  },
  // {
  //   value: '2',
  //   label: '区域',
  // },
  {
    value: '3',
    label: '门店',
  },

]
